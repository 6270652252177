.site-title {
  display: block;
  @include BigShouldersDisplayBold;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 2em;
  line-height: 1;
  color: $color-blue-dark;
  margin: 1.5em 0 1.1em;

  .sub {
    display: block;
    font-size: 1.5em;
    color: $color-red;
  }

  &:hover {
    color: $color-blue-dark;
  }
}

.imaginary-project {
  display: block;
  margin-top: 3.3em;
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
}
