.museum-map {
  .museum-map-side-image {
    display: none;
  }

  .museum-map-side-image.active {
    display: block;
  }
}

.side-selector {
  margin-top: 2em;

  .side-selector-title {
    @include headingText;
    font-size: 1.5em;
    color: $color-black;
  }
  .nav {
    .nav-item {
      @include headingText;
      font-size: 1.5em;
      margin-right: 0.25em;

      &:last-child {
        margin-right: 0;
      }

      .nav-link {
        color: $color-blue-dark;
        padding: 0.5rem 1.2rem;
        background-color: $color-white;

        &.active {
          background-color: $color-blue-dark;
          color: $color-white;
        }
      }
    }
  }
}

.museum-map-section-menu {
  margin-top: 2em;
  .nav-item {
    margin-bottom: 0.3em;

    .nav-link {
      text-align: left;
      background-color: $color-white;
      border: none;
      border-radius: 0.25rem;
      color: $color-black;
      padding-left: 2.7em;

      .section-letter {
        position: absolute;
        font-size: 1.2em;
        margin-top: -0.2em;
        margin-left: -1.35em;
        font-weight: bold;
        color: $color-fucsia;
      }
    }
  }
}

.museum-texts {
  display: none;
}
