.navbar {
  background-color: $color-blue-dark;

  .navbar-nav {
    @include media-breakpoint-down(md) {
      margin-top: 1em;
    }
    .nav-item {
      .nav-link {
        @include headingText;
        font-size: 1.5em;
        margin-left: 0;
        margin-right: 2em;
        padding-left: 0;
        padding-right: 0;

        &.active {
          @include media-breakpoint-up(md) {
            border-bottom: 3px solid $color-white;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.navbar-dark .navbar-toggler {
  border-color: white!important;
  border-width: 2px;
}
