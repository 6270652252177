$base-font-size: 16px;
$base-line-height: 1.3;

@mixin OpenSans {
  font-family: "Open Sans", Helvetica Neue, Helvetica, Arial, sans-serif;
}

@mixin BigShouldersDisplayBold {
  font-family: "Big Shoulders Display", sans-serif;
  font-weight: 700;
}

@mixin SueEllenFranciscoRegular {
  font-family: "Sue Ellen Francisco", sans-serif;
  font-weight: 400;
}

@mixin regularText {
  @include OpenSans;
}

@mixin headingText {
  @include BigShouldersDisplayBold;
  text-transform: uppercase;
}

@mixin handwritingText {
  @include SueEllenFranciscoRegular;
}
