.museum-text {
  font-size: 18px;
  line-height: 1.5;

  h1 {
    font-size: 3.5rem;
  }

  h2 {
    color: $color-red;
    font-size: 3.5rem;
    margin-bottom: 0.3em;
    margin-top: 0;
    @include media-breakpoint-down(sm) {
      font-size: 2.75rem;
    }
  }

  h3 {
    font-size: 2.25rem;
    margin: 1em 0 0.35em;

    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
    }
  }

  h4 {
    @include regularText;
    font-weight: bold;
    font-size: 1.25rem;
    margin: 1.2em 0 0.2em;
  }
}
