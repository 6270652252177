#footer {
  background-color: $color-blue-dark;
  color: $color-white;
  margin-top: 4em;
  padding: 2em 0 1em;

  a {
    color: $color-white;
    text-decoration: none;
    &:hover {
      color: $color-white;
      text-decoration: underline;
    }
  }
}

.menu-footer-nav {
  margin-bottom: 2em;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      padding: 0;
      margin: 0 0 0.2em 0;
    }
  }
}

.copyright-notice {
  font-size: 0.9em;
}

#footer-logos {
  padding: 2em 0 1.5em;
  margin-bottom: -4em;
  @include media-breakpoint-up(md) {
    margin-top: 5em;
  }
}

.wrapper-logo-10mm {
  padding-right: 0;
  @include media-breakpoint-up(md) {
    padding-right: 1em;
  }
  @include media-breakpoint-up(xl) {
    padding-right: 4em;
  }
}

.wrapper-logo-imaginary,
.wrapper-logo-kts {
  padding-left: 0;
  @include media-breakpoint-up(md) {
    padding-left: 1em;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 4em;
  }
}

.footer-logo {
  display: block;
  img {
    width: 100%;
  }
}
