$color-black-real: #000;
$color-white-real: #fff;

$color-black: #333;
$color-white: $color-white-real;
$color-gray-light: #dcdcdc;
$color-gray-dark: #7c7c7c;

$color-blue-dark: #24436e;
$color-red: #eb4c42;
$color-fucsia: #fe1d74;

$color-blue-light: #d9e7fe;

@mixin neg {
  -webkit-font-smoothing: antialiased;
}
