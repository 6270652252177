html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  @include regularText;
  font-size: $base-font-size;
  line-height: $base-line-height;
  background-color: $color-white;
  color: $color-black;
}

h1, h2, h3, h4, h5, h6 {
  @include BigShouldersDisplayBold;
  text-transform: uppercase;
  color: $color-blue-dark;
}

h1 {
  font-size: 4rem;
  margin-bottom: 0.5em;

  @include media-breakpoint-down(sm) {
    font-size: 2.75rem;
  }
}

h2 {
  color: $color-red;
  font-size: 2.75rem;
  line-height: 1.1;
  margin-bottom: 0.25em;
  margin-top: 0.75em;

  @include media-breakpoint-down(sm) {
    font-size: 2.25rem;
  }
}

h3 {
  font-size: 2.25rem;
  margin: 1em 0 0.35em;

  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
  }

}

h4 {
  @include regularText;
  font-weight: bold;
  font-size: 1.25rem;
  margin: 1.2em 0 0.2em;
}

.lead {
  font-size: 1.3rem;
  font-weight: 400;

  @include media-breakpoint-down(sm) {
    font-size: 1.2rem;
  }
}

.large {
  @include headingText;
  text-transform: none;
  font-size: 2rem;
  color: $color-blue-dark;
}

.highlighted {
  color: $color-red;
}

.handwriting {
  @include handwritingText;
  font-size: 1.5rem;
  line-height: 1.6;
  letter-spacing: 0.075rem;
  margin: 1rem 0;
}

.modal-backdrop.show {
  opacity: 0.7;
}

.mini-map {
  iframe {
    display: block;
    width: 100%;
  }
}

.thumbnail {
  border: 1px solid $color-gray-dark;
  display: block;
}

.zoomable {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -0.4em;
    right: -0.7em;
    width: 2em;
    height: 2em;
    background-image: url('../img/fa/magnifying-glass-plus-solid.svg');
    background-size: 1em 1em;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $color-white;
    border: 3px solid $color-fucsia;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
}

.credits {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
