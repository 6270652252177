$close-button-size: 46px;
$close-button-border-size: 4px;

.modal-dialog .close {
  position: relative;
  z-index: 100;
  width: $close-button-size;
  height: $close-button-size;
  border: $close-button-border-size solid $color-fucsia;
  border-radius: 50%;
  background-color: $color-white;
  color: $color-fucsia;
  margin-top: 2px;
  margin-right: -16px;

  font-size: $close-button-size;
  font-weight: bold;
  line-height: $close-button-size - (2 * $close-button-border-size + 8px);

  @include media-breakpoint-only(xs) {
    margin-top: 22px;
    margin-right: -6px;
  }
}

.modal-header {
  padding: 0;
  border-bottom: none;
  height: 10px;
}
